<template>
  <div>
    <el-collapse-transition>
      <!--搜索框-->
      <TableSearch v-show="showSearch" :query-params="queryParams" @search="handleSearch" @reset="handleReset">
        <el-form :model="queryParams" ref="queryForm" label-suffix=":" label-width="100px">

          <el-row :gutter="10">

            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
              <el-form-item prop="paperName" label="试卷名称">
                <el-input v-model="queryParams.paperName" placeholder="请输入试卷名称" clearable/>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item prop="nickname" label="答题人">
                <el-input v-model="queryParams.nickname" placeholder="请输入答题人" clearable/>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="答题日期">
                <el-date-picker
                    v-model="dateRange"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </TableSearch>
    </el-collapse-transition>

    <div>
      <el-button style="width: 80px" size="mini" type="success" @click="handleSave">添加</el-button>
      <el-button style="width: 80px" size="mini" type="primary" @click="showSearch = !showSearch">
        {{ showSearch ? '隐藏搜索' : '显示搜索' }}
      </el-button>
    </div>

    <!--列表-->
    <div>
      <el-table
          :data="tableData"
          stripe
          style="width: 100%; font-size: 15px"
          size="medium"
          @sort-change="handleSortChange"
          :default-sort = "{prop: 'createTime', order: 'descending'}"
      >
        <el-table-column type="index" width="50"/>

        <el-table-column prop="nickname" label="答题人"/>

        <el-table-column prop="paperName" label="试卷名称"/>

        <el-table-column prop="rate" label="正确率" sortable="custom"/>

        <el-table-column prop="createTime" label="答题时间" sortable="custom"/>

        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" @click="handleView(scope.row)">查看</el-button>
            <!--<el-button type="text" @click="handleUpdate(scope.row)">修改</el-button>-->
            <el-button type="text" class="color-danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--<el-divider/>-->

      <pagination
          v-show="queryParams.total>0"
          :total="queryParams.total"
          :page.sync="queryParams.curPage"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
      />
    </div>

    <CommonDialog
        ref="dialogView"
        auto-close
        type="primary"
        title="查看"
        :show-confirm="false"
        cancel-text="关闭"
        @close="currentViewIndex = 0"
        width="1000px"
        top="100"
    >
      <div class="view-dialog">

        <el-row type="flex" justify="center">
          <el-col :span="10">
            <div style="width: 340px;  border-right: #d8d8d8 1px solid;">
              <el-form ref="form" :model="formData" :rules="rules" label-width="100px" label-suffix="：">
                <el-form-item label="试卷名称">
                  <el-input v-model="formData.paperName" readonly/>
                </el-form-item>

                <el-form-item label="答题人">
                  <el-input v-model="formData.nickname" readonly/>
                </el-form-item>

                <el-form-item label="题目数量">
                  <el-input v-model="formData.questionNum" readonly/>
                </el-form-item>

                <el-form-item label="正确数量">
                  <el-input v-model="formData.correctNum" readonly/>
                </el-form-item>

                <el-form-item label="正确率">
                  <el-input v-model="formData.rate" readonly/>
                </el-form-item>

                <el-form-item label="答题用时">
                  <el-input v-model="formData.span" readonly/>
                </el-form-item>

                <el-form-item label="答题时间">
                  <el-input v-model="formData.createTime" readonly/>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
          <el-col :span="14">
            <div class="question-box" :style="cssVars" v-if="viewDetailList.length">
              <div class="image">
                <img :src="viewDetailList[currentViewIndex].isCorrect ? '/assets/images/check.png' : '/assets/images/close.png'"/>
              </div>
              <div class="question-info">
                <el-tag :type="viewDetailList[currentViewIndex].type === '多选题' ? 'warning' : 'primary'">
                  {{ viewDetailList[currentViewIndex].type }}
                </el-tag>
                {{ currentViewIndex + 1 }}、{{ viewDetailList[currentViewIndex].question }}
              </div>
              <div class="option-info">
                <div class="option-item" v-for="(item, index) in viewDetailList[currentViewIndex].options.split('::')">
                  {{ optionPrefix[index] }}、{{ item }}
                </div>
              </div>
              <div class="option-answer">
                <div>正确答案：<span
                    v-for="(item, index) in viewDetailList[currentViewIndex].correct.split(',')">{{ optionPrefix[item] }}</span>
                </div>
                <div>答题选择：<span
                    v-for="(item, index) in viewDetailList[currentViewIndex].selected.split(',')">{{ optionPrefix[item] }}</span>
                </div>
              </div>
              <div class="question-footer">
                <el-button type="text" :disabled="currentViewIndex === 0"
                           @click="currentViewIndex -= 1">上一题
                </el-button>

                <el-popover
                    placement="top"
                    width="400"
                    trigger="click">
                  <div class="card" style="height: 300px;overflow: auto;">
                    <div @click="currentViewIndex = index" :class="index === currentViewIndex ? 'selected' : ''" style="display: flex; align-items: center;margin-bottom: 4px;" v-for="(item, index) in viewDetailList">
                      <el-tag :type="item.type === '多选题' ? 'warning' : 'primary'">
                        {{ item.type }}
                      </el-tag>
                      <span style="width: 100%;margin-left: 2px;white-space: nowrap;overflow: hidden;text-overflow:ellipsis;position: relative">
                        {{ index + 1 }}、{{ item.question }}
                        <img
                            style="position: absolute; right: 0;"
                            :src="item.isCorrect ? '/assets/images/check.png' : '/assets/images/close.png'"
                            width="10px"
                            height="10px">
                      </span>
                    </div>
                  </div>
                  <el-button type="text" slot="reference">答题卡</el-button>
                </el-popover>

                <el-button type="text" :disabled="currentViewIndex === viewDetailList.length - 1"
                           @click="currentViewIndex += 1">下一题
                </el-button>
              </div>

            </div>
          </el-col>
        </el-row>
      </div>

    </CommonDialog>

  </div>
</template>

<script>
import TableSearch from "@/components/TableSearch/TableSearch.vue";
import Pagination from "@/components/Pagination/index.vue";
import CommonDialog from "@/components/CommonDialog.vue";
import DictTag from "@/components/DictTag/index.vue";

export default {
  name: "index",
  components: {DictTag, CommonDialog, Pagination, TableSearch},
  data() {
    return {
      queryParams: {
        total: 0,
        curPage: 1,
        pageSize: 10,
        paperName: '',
        nickname: '',
        sortColumn: '',
        sortOrder: '',
      },
      tableData: [],
      showSearch: false,
      formData: {
        paperName: '',
        nickname: '',

      },
      optionPrefix: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
      innerHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
      rules: {
        paperName: [
          {required: true, message: '请输入试卷名称', trigger: 'blur'},
          {min: 1, max: 50, message: '1~100字符', trigger: 'blur'}
        ],
        nickname: [
          {required: true, message: '请选择文章类型', trigger: ['blur', 'change']}
        ],
      },
      dialogInfo: {
        title: '',
        type: '',
        disabled: false,
      },
      dateRange: [],
      viewDetailList: [],
      currentViewIndex: 0
    }
  },
  mounted() {
    this.getList()
  },
  computed: {
    cssVars() {
      console.log('this.innerHeight', this.innerHeight)
      return {
        "max-height": this.innerHeight - 200 + 'px',
        "height": 500 + 'px'
      };
    }
  },

  methods: {
    getList() {
      this.$api.answer.page(this.$addDateRange(this.queryParams, this.dateRange)).then(res => {
        this.tableData = res.list
        this.queryParams.total = res.total
        this.queryParams.pages = res.pages
      })
    },

    handleSortChange(e) {
      this.queryParams = this.$handleSort(this.queryParams, e)
      this.getList()
    },

    resetFormData() {
      this.$resetForm('form')
    },

    handleSave() {
      this.dialogInfo.title = '新 增'
      this.dialogInfo.type = 'success'
      this.$refs.dialog.show()
    },

    handleClose() {
      this.resetFormData()
    },

    handleView(row) {
      if (row.id) {
        this.$api.answer.get(row.id).then(res => {
          this.$refs.dialogView.show()
          this.formData = res

          this.getViewDetail(res.id)
        })
      }
    },

    getViewDetail(historyId) {
      this.$api.answerDetail.list({historyId: historyId}).then(res => {
        this.$refs.dialogView.show()
        this.viewDetailList = res
      })
    },

    handleUpdate(row) {
      this.$api.answer.get(row.id).then(res => {
        this.dialogInfo.title = '修 改'
        this.dialogInfo.type = 'warning'
        this.$refs.dialog.show()
        this.$nextTick(() => {
          this.formData = res
        })
      })
    },

    handleDelete(row) {
      this.$dialog({
        type: 'danger',
        content: `确认删除 “ <b class="color-danger">${row.title}</b> ”？`
      }).then(() => {
        this.$api.answer.del(row.id).then(() => {
          this.getList()
          this.$message.success('删除成功')
        })
      })
    },

    handleSubmitConfirm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            this.$api.answer.update(this.formData).then(res => {
              this.$message.success('修改成功')
              this.$refs.dialog.close()
              this.getList()
            }).catch(() => {
              this.$refs.dialog.stopLoading()
            })
          } else {
            this.$api.answer.save(this.formData).then(res => {
              this.$message.success('新增成功')
              this.$refs.dialog.close()
              this.getList()
            }).catch(() => {
              this.$refs.dialog.stopLoading()
            })
          }
        } else {
          this.$message.warning('请填写完整')
          this.$refs.dialog.stopLoading()
        }
      })
    },

    handleSearch() {
      this.queryParams.curPage = 1
      this.getList()
    },

    handleReset() {
      this.dateRange = []
      this.$resetForm('queryForm')
      this.$nextTick(() => {
        this.handleSearch()
      })
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep .view-dialog .el-input__inner {
  border: none;
  padding: 0 0;
}

.question-box {
  display: flex;
  flex-direction: column;
  position: relative;

  .image {
    position: absolute;
    right: 0;
    opacity: 0.1;
  }

  .option-info {
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    .option-item {
      display: flex;
      align-items: center;
      padding: 0 20px;
      height: 40px;
      border-radius: 10px;
      //border: #dfdddd 0.5px solid;
      margin-bottom: 10px;
    }
  }

  .option-answer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .question-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
  }
}

.selected {
  background: #d5e4f4;
}
</style>
